import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Link from "../../utils/link";

function Previous(props) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0.5 0.5 13.78 26"
      className="w-full h-full"
      {...props}
    >
      <path d="M13.217 26L1 13.782 13.783 1" stroke="#000" />
    </svg>
  );
}

function Next(props) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0.72 0.5 13.78 26"
      className="w-full h-full"
      {...props}
    >
      <path d="M1.782 1L14 13.218 1.218 26" stroke="#000" />
    </svg>
  );
}

const ButtonWrapper = (props) => {

  const slug = props.project.node.full_slug;
  // remove projects/ and deslugify
  const deSlugified = slug.replace("projects/", "").replace(/-/g, " ");

  return (
    <Link
      className={`flex items-center justify-center uppercase tracking-wider group ${!props.next ? `flex-row-reverse` : ``}`}
      to={slug}
    >
      <span className={`w-[22px] h-[22px] duration-300 transition-all ${props.next ? `group-hover:-translate-x-2` : `group-hover:translate-x-2`}`}>
        {!props.next ? <Next /> : <Previous />}
      </span>{" "}
      <span
        className={`inline-block ${!props.next ? `pr-[15px]` : `pl-[15px]`}`}
      >
        {props.next ? `Previous` : `Next`} <span className="hidden lg:inline-block">Project</span>- {deSlugified}
      </span>
    </Link>
  );
};

const ProjectPagenation = (props) => {


  const data = useStaticQuery(graphql`
    query ProjectList {
      allStoryblokEntry(filter: { tag_list: { in: "project" } }) {
        edges {
          node {
            id
            full_slug
          }
        }
      }
    }
  `);

  const projects = data.allStoryblokEntry.edges;

  const currentProject = projects.find(
    (project) => project.node.full_slug === props.slug
  );

  const currentIndex = projects.indexOf(currentProject);

  // Find next project. If current project is last project, set next project to first project

  const nextProject = projects[currentIndex + 1] ? projects[currentIndex + 1] : projects[0];

  // Find previous project. If current project is first project, set previous project to last project

  const previousProject = projects[currentIndex - 1] ? projects[currentIndex - 1]: projects[projects.length - 1];

  return (
    <section className="pt-[98px] pb-[80px] lg:pt-[150px] lg:pb-[111px] font-bold">
      <div className="container-theme grid lg:grid-cols-2 gap-y-[46px] lg:gap-y-0">
      <div className="flex items-center justify-start">
          <ButtonWrapper next={true} project={nextProject} />
        </div>

        <div className="flex items-center justify-end">
          <ButtonWrapper next={false} project={previousProject} />
        </div>
      </div>
    </section>
  );
};

export default ProjectPagenation;
