import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from 'gatsby'
import useStoryblok from "../lib/storyblok"
import { sbEditable } from "@storyblok/storyblok-editable"
import DynamicComponent from "../components/dynamicComponent";
import { Helmet } from "react-helmet";
import ProjectPagenation from "../sections/Projects/ProjectPagenation";


const DoNotIndex = () => {
  return(
    <Helmet 
    robots={`noindex`}
  />
  )
}

function StoryblokEntry({data, location}){
  
  if(data.storyblokEntry.full_slug == `footer` || data.storyblokEntry.full_slug == `header`){
    return DoNotIndex
  }

  let story = data.storyblokEntry
  
  story = useStoryblok(story, location)
  

    const components = story.content.blocks.map(blok => {
    return (<DynamicComponent blok={blok} key={blok._uid} />)
  })

  const padBottom = story.content.pad_bottom || false
 
  // check full_slug to see if it's a project subpage

  const isProject = story.full_slug.includes(`projects/`) && story.full_slug !== `projects/`




  return (
    <Layout location={location}   {...sbEditable(story.content)}>
      <SEO
        keywords={[`Trust Projects`]}
        title={story.content.seo_title || ""}
        description={story.content.seo_description || ""}
        socialSharing={story.content.social_sharing?.filename || ""}

      />
              {components}

      {padBottom && <div className='h-[65px] lg:h-[144px]'></div>}

      {isProject && <ProjectPagenation slug={story.full_slug}/>}

    </Layout>
  );
}

export const query = graphql`
  query ($full_slug: String!) {
    storyblokEntry(full_slug: { eq: $full_slug }) {
      id
      name
      full_slug
      content
    }
  }
`


export default StoryblokEntry;
